<script setup>
  import { RouterView } from 'vue-router'
  import { useKeycloak } from './composables/keycloak.js'
  const { init, access_token } = useKeycloak()

  const token = new URL(window.location.href).searchParams.get('token');

  if (!token) {
    init()
  }
</script>
<template>
  <RouterView v-if="access_token || token"/>
</template>

<style>

@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body {
	margin: 0;
	overflow: hidden;
  font-family: "Montserrat", sans-serif;
  /* font-family: "new-zen", sans-serif;
  font-weight: 400;
  font-style: normal; */
  background-color: #091938;
  color: white;
}
</style>