<template>
    <div v-if="loaded" id="page">
        <div id="header">
            <div class="line"></div>
            <div class="line"></div>
            <div v-if ="team=='groningen'" class="logo-groningen"></div>
            <div v-if ="team=='utrecht'" class="logo-utrecht"></div>
            <div v-if ="team=='amsterdam'" class="logo-amsterdam"></div>
            <div v-if ="team=='cherries'" class="logo-cherries"></div>
            <div v-if ="team==null || team=='all'" class="logo"></div>
        </div>

        <!-- <div>{{ teams }}</div>
        <div>{{ mapped_team }}</div> -->
        <div v-if="team == null" class="canvas">

            <template v-for="(item, item_index) in theIndividuals" :key="item_index">
                <div v-if="isInPage(item_index)" class="column">
                        <div v-for="(member, index) in item" :key="index" class="item" :class="{ 'not-compliant': !member.is_compliant }">
                            <img v-if="member.scores.battle_team == 52" src="@/assets/team2024/utrecht.png"  width="75" height="75" class="team_logo"/>
                            <img v-if="member.scores.battle_team == 53" src="@/assets/team2024/groningen.png" width="75" height="75" class="team_logo"/>
                            <img v-if="member.scores.battle_team == 54" src="@/assets/team2024/amsterdam.png" width="75" height="75" class="team_logo"/>
                            <img v-if="member.scores.battle_team == 55" src="@/assets/team2024/certified.png" width="75" height="75" class="team_logo"/>
                            <img v-if="member.scores.battle_team == 56" src="@/assets/team2024/cherries.png" width="75" height="75" class="team_logo"/>
                            <div class="rank">{{(item_index * 10) + index + 1}}.</div>
                            <div class="name">{{ member.first_name }} {{ member.last_name }}</div>
                            <div class="compliance" >{{ member.compliance_pct ? member.compliance_pct + '%' : '' }}</div>
                            <div class="scores">{{ Math.floor(member.score_total) }}</div>
                        </div>
                    </div>
                </template>
        </div>

        <div v-if="team == 'all'" class="canvas">
            <div class="teams">
                <template v-for="(item, item_index) in mapped_team" :key="item_index">
                    <div class="card">
                    <div class="background"></div>
                    <img v-if="item.team_id == 52" src="@/assets/team2024/utrecht.png"  width="147" height="147" class="team_logo"/>
                    <img v-if="item.team_id == 53" src="@/assets/team2024/groningen.png" width="147" height="147" class="team_logo"/>
                    <img v-if="item.team_id == 54" src="@/assets/team2024/amsterdam.png" width="147" height="147" class="team_logo"/>
                    <img v-if="item.team_id == 55" src="@/assets/team2024/certified.png" width="147" height="147" class="team_logo"/>
                    <img v-if="item.team_id == 56" src="@/assets/team2024/cherries.png" width="147" height="147" class="team_logo"/>
                    <svg font-family="Erbaum" height="80" width="300px" class="team_score">
                    <text id="textid" x="88%" y="70" fill="white">{{ Math.floor(item.score).toLocaleString('nl-NL') }}</text>
                    Sorry, your browser does not support inline SVG.
                    </svg>
                    </div>

                    <!-- <div class="team_score">
                        {{ Math.floor(item.score).toLocaleString('nl-NL') }}</div>
                    </div> -->
                    </template>
                </div>
        </div>
        <div v-if="team !== null && team !== 'all'" class="canvas">
            <template v-for="(item, item_index) in theSplittedTeam" :key="item_index">
            <div v-if="isInTeamPage(item_index)" class="column" >
                <div v-for="(member, index) in item" :key="index" class="team-item" :class="{ 'not-compliant': !member.is_compliant }">
                    <div class="rank">{{(item_index * 16) + index + 1}}.</div>
                    <div class="name">{{ member.first_name }} {{ member.last_name }}</div>
                    <div class="compliance" >{{ member.compliance_pct ? member.compliance_pct + '%' : '' }}</div>
                    <div class="scores">{{ Math.floor(member.score_total)  }}</div>
                </div>
            </div>
        </template>

        </div>
        </div>

</template>

<style scoped>

text {
        paint-order: stroke;
        stroke: #0B1F47;
        stroke-width: 28px;
        font-weight: 900;
        font-size:60px;
        right:0%;
        text-anchor: end;
    }

    #header {
        position: relative;
        margin-top: 31px;
        height: 100px;
        background: linear-gradient(-45deg, #45E4BF, #FFE347, #45E4BF, #FFE347);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        }
        /* background: linear-gradient(90deg, #45E4BF 0%, #FFE347 100%); */
        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }

    .line {
        position: absolute;
        margin-top: 17px;
        height: 7px;
        width: 100%;
        background-color: black;
    }

    .line:nth-child(2) {
        margin-top: 41px;
    }

    .logo {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 150px;
        background: url(../assets/team2024/logo.png) no-repeat center;
        background-size: contain;
    }

    .logo-groningen {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 150px;
        background: url(../assets/team2024/groningen.png) no-repeat center;
        background-size: 150px;
    }

    .logo-utrecht {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 150px;
        background: url(../assets/team2024/utrecht.png) no-repeat center;
        background-size: 150px;
    }

    .logo-cherries {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 150px;
        background: url(../assets/team2024/cherries.png) no-repeat center;
        background-size: 150px;
    }

    .not-compliant {
        opacity: 0.4;
    }

    .logo-amsterdam {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 150px;
        background: url(../assets/team2024/amsterdam.png) no-repeat center;
        background-size: 150px;
    }

    .card {
        display: flex;
        position: relative;
        /* background-color: #0B1F47; */
        margin: 0px 0 20px 0;
        padding: 0px;
        justify-content: space-around;
        align-items: center;

    }

    .background {
        position: absolute;
        width: 100%;
        bottom: 0;
        top: 50%;
        background-color: #0B1F47;
    }

    .teams {
        width: 25%;
    }

    .team_score {
        /* margin-top: 32px;
        margin-right: 20px; */

        /* font-weight: 900;
        text-align: right; */
        position: relative;
        /* color: #fff;
        font-size: 60px; */



    }

    .canvas {
        margin-top: 30px;
        height: 80vh;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
        display: flex;
        overflow: hidden;
    }

    .column {
        width: 30%;
        height: 100%;
        padding: 18px 38px ;
        box-sizing: border-box;
        background-color: #0B1F47;
        display: flex;
        flex-direction: column;
    }

    .item {
        display: flex;
        font-size: 17px;
        height: 50px;
        margin-top: 32px;
        align-items: center;
    }

    .team-item {
        display: flex;
        font-size: 18px;
        margin-top: 30px;
        align-items: center;
    }

    .team_logo {
        margin-right: 5px;
        position: relative;
    }

    .rank {
        width: 35px;
        margin-right: 5px;
    }

    .name {
        width: 100%;
    }

    .compliance {
        width: 100px;
    }

    .scores {
        width: 120px;
        text-align: right;
    }



</style>

<script setup>

import { onMounted, computed, ref } from 'vue'
import { useBattleData } from '../composables/battledata.js'
import { useBattleLoader } from '../composables/battleloader.js'

const {  individuals, teams } = useBattleData({ filter_date: false, sort_excludes_additional_points: true })


// const {  teams as myteam } = useBattleData({ filter_date: true, sort_excludes_additional_points: true })
// const { teams as teams_today } = useBattleData({ filter_date: true, sort_excludes_additional_points: true })
// const { initBattle, loadProductionData,  loaded } = useBattleLoader()
const { initBattle, loaded, loadProductionData } = useBattleLoader()
const theIndividuals = computed(splittedIndividuals)
const theTeam = computed(filteredTeam)
const theSplittedTeam = computed(splittedTeam)

let reload_timer = undefined


const pageMeta = computed(() => {
    const chunckCount = theIndividuals.value ? theIndividuals.value.length : 0
    return {
        "chunckCount": chunckCount,
        "pages": Math.ceil(chunckCount / 3)
    }
})

const teamMeta =  computed(() => {
    const chunckCount = theSplittedTeam.value ? theSplittedTeam.value.length : 0
    return {
        "chunckCount": chunckCount,
        "pages": Math.ceil(chunckCount / 3)
    }
})

let slide_timer = undefined
const activePage = ref(0)
const activeTeamPage = ref(0)
const team = ref(null)

const mapped_team = computed(() => {
    const mapped = teams.value.map((el) => {
        return { 'team_name': el.name, 'team_id': el.id, 'score': el.team_points.grand_total }
    })
    return mapped
})

function setReloadTimer() {
    if (!reload_timer){
        clearInterval(reload_timer)
        reload_timer = undefined
    }
    // reload_timer = setInterval(loadProductionData(battle_data.value.id), 120000)
    reload_timer = setInterval(() => loadProductionData(), 120000)
}


function isInPage( item_index ) {
    return item_index >= (activePage.value * 3) && item_index < (activePage.value * 3 + 3)
}

function isInTeamPage( item_index ) {
    return item_index >= (activeTeamPage.value * 3) && item_index < (activeTeamPage.value * 3 + 3)
}



function doSlide(direction='up') {

    if (direction == 'up') {
        activePage.value = activePage.value == pageMeta.value.pages - 1 ? 0 : activePage.value + 1;
        activeTeamPage.value = activeTeamPage.value == teamMeta.value.pages - 1 ? 0 : activeTeamPage.value + 1;
    }
    else {
        activePage.value = activePage.value == 0 ? pageMeta.value.pages - 1 : activePage.value - 1;
        activeTeamPage.value = activeTeamPage.value == 0 ? teamMeta.value.pages - 1 : activeTeamPage.value - 1;
    }
}

function setupSlideTimer() {
    if (!slide_timer) {
    slide_timer = setInterval(() => {
        doSlide();
    }, 12000);
    }
}

// function setReloadTimer() {
//     if (!reload_timer){
//         clearInterval(reload_timer)
//         reload_timer = undefined
//     }
//     // reload_timer = setInterval(loadProductionData(battle_data.value.id), 120000)
//     reload_timer = setInterval(() => loadProductionData(), 120000)
// }


onMounted(async () => {
    await initBattle().then(() => {
        setupSlideTimer()
        setReloadTimer()
        team.value = new URL(window.location.href).searchParams.get('team');


    })
    loaded.value = true
})


function splittedIndividuals() {

    return individuals.value.reduce((acc, item, index) => {

        const chunkIndex = Math.floor(index / 10)
        if (!acc[chunkIndex]) {
            acc[chunkIndex] = []
        }
        acc[chunkIndex].push(item)
        return acc
    }, [])
}

function filteredTeam() {

    const filtered_team = teams.value.filter((el) => {
        return el.name.toLowerCase() == team.value
    });

    if (filtered_team.length > 0) {
        return filtered_team[0]
    }
    return {}

}

function splittedTeam() {
    if(theTeam.value.members) {
        return theTeam.value.members.reduce((acc, item, index) => {

            const chunkIndex = Math.floor(index / 16)
            if (!acc[chunkIndex]) {
                acc[chunkIndex] = []
            }
            acc[chunkIndex].push(item)
            return acc
        }, []);
    }
}

</script>
