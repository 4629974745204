import { ref } from 'vue'
import { useBattleData } from './battledata.js'
import { useFetch } from './fetch.js'
const { doFetch } = useFetch()
const { battle_data, battle_teams, battle_members, battle_modifiers, battle_sales,battle_compliance, battle_accelerators, battle_additional_points, battle_matches } = useBattleData({filter_date: false, sort_excludes_additional_points:true})

const loaded = ref(false);

export function useBattleLoader() {

    async function initBattle() {
        // const battle_data_url = `${process.env.VUE_APP_BATTLE}?fields=id,name,start_date,end_date,team_mode&filter={"_and":[{"start_date":{"_lte":"$NOW"}},{"end_date":{"_gte": "$NOW"}}]}`
        const battle_data_url = `${process.env.VUE_APP_BATTLE}?fields=id,name,start_date,end_date,team_mode&filter={"_and":[{"id":{"_eq":6}}]}`
        console.log('fetching battle data')
        battle_data.value = await doFetch(battle_data_url, { single: true })
        return loadStaticData()
    }

    function loadStaticData() {
        const battle_id = battle_data.value.id;
        const battle_teams_url = `${process.env.VUE_APP_TEAMS}?filter={"battle":{"_eq":${battle_id}}}`;
        // const battle_members_url = `${process.env.VUE_APP_MEMBERS}?filter={"_and":[{"battle":{"_eq":${battle_id}}}]}&fields=battle_user.first_name,battle_user.last_name,battle_user.user_id,battle_team,is_captain&limit=-1`;
        const battle_modifiers_url = `${process.env.VUE_APP_CAMPAIGN_MODIFIER}?filter={"battle":{"_eq":${battle_id}}}&limit=-1`;
        const battle_matches_url = `${process.env.VUE_APP_MATCHES}?filter={"battle_id":{"_eq":${battle_id}}}&limit=-1`;
        doFetch(battle_teams_url).then((data) => battle_teams.value = data)
        // doFetch(battle_members_url).then((data) => battle_members.value = data)
        doFetch(battle_modifiers_url).then((data) => battle_modifiers.value = data)
        doFetch(battle_matches_url).then((data) => battle_matches.value = data)

        return Promise.all([battle_teams.value, battle_modifiers.value, battle_matches.value]).then(() =>
            loadMembers(battle_id).then(() => loadProductionData())
        )
    }

    async function loadMembers(battle_id) {
        // load members from user table or battle_members. The resulting format must be the same.
        if (battle_data.value.team_mode && battle_data.value.team_mode === 'user') {
            const battle_users_url = `${process.env.VUE_APP_USERS}?filter={"_and":[{"status":{"_eq":"Actief"}},{"team":{"_neq":"White Label Partners"}}]}&fields=first_name,last_name,team,user_id,certified_user_id&limit=-1`;

            doFetch(battle_users_url).then((data) => battle_members.value = data.map((user) => {
                const mapped_team = mappedTeam(user.team, user.certified_user_id)
                const found_team = battle_teams.value.find((team) => team.name === mapped_team)

                let first_name = user.first_name
                let last_name = user.last_name

                if (mapped_team == 'Certified Partners') {
                    first_name = 'Certified'
                    last_name = 'Partner'
                }
                return {
                    battle_team: found_team ? found_team.id: 0,
                    battle_user: {
                        first_name: first_name,
                        last_name: last_name,
                        user_id: user.user_id
                    },
                    is_captain: null
                }
            }
            ))
        } else {
            const battle_members_url = `${process.env.VUE_APP_MEMBERS}?filter={"_and":[{"battle":{"_eq":${battle_id}}}]}&fields=battle_user.first_name,battle_user.last_name,battle_user.user_id,battle_team,is_captain&limit=-1`;
            doFetch(battle_members_url).then((data) => battle_members.value = data)
        }
    }

    function mappedTeam(team, certified_partner_id) {
        if (team === 'Certified Partners') {
            if (certified_partner_id === "28835") {
                return "Cherries"
            }
        }
        return team
    }

    function loadProductionData() {
        const battle_id = battle_data.value.id;
        const sales_url = `${process.env.VUE_APP_SALES}?filter={"_and":[{"sale_closed_date":{"_gte":"${battle_data.value.start_date}"}},{"sale_closed_date":{"_lte":"${getEndDate()}"}}]}&aggregate[count]=*&aggregate[sum]=fee_partner&groupBy=campaign_code,user_id,sale_closed_date&limit=-1`;
        const compliance_url = `${process.env.VUE_APP_COMPLIANCE}?filter={"battle_id":{"_eq":${battle_id}}}&limit=-1`;
        const accelerators_url = `${process.env.VUE_APP_ACCELERATORS}?filter={"battle":{"_eq":${battle_id}}}&limit=-1`;
        const additional_points_url = `${process.env.VUE_APP_ADDITIONAL_POINTS}?filter={"battle":{"_eq":${battle_id}}}&limit=-1`;
        doFetch(sales_url).then((data) => battle_sales.value = data)
        doFetch(compliance_url).then((data) => battle_compliance.value = data)
        doFetch(accelerators_url).then((data) => battle_accelerators.value = data)
        doFetch(additional_points_url).then((data) => battle_additional_points.value = data)
        console.log('load production')
        return Promise.all([battle_sales.value, battle_compliance.value, battle_accelerators.value, battle_additional_points.value]).then(() =>
            // loaded.value = true
            true != false
        )
    }

    function getEndDate() {
        const endDate = battle_data.value.end_date
        const dt = new Date(Date.parse(endDate.toString()))
        return new Date(dt.setDate(dt.getDate() + 1)).toISOString().slice(0, 10)
    }

    return {loadProductionData, initBattle, loaded}

}