import { ref, computed } from 'vue'
import { useBattleTools } from './battletools.js'
const battle_data = ref({})
const battle_teams = ref([])
const battle_members = ref([])
const battle_modifiers = ref([])
const battle_sales = ref([])
const battle_compliance = ref([])
const battle_accelerators = ref([])
const battle_additional_points = ref([])
const battle_matches = ref([])


const { isCompliant, isDateApplicable, getFilterDate } = useBattleTools()

// if filter_date is given, sales will be filtered by date
export function useBattleData(options) {

    // console.log(options)
    const individuals = computed(() => {

        return getIndividual(true)
    })

    const individuals_today = computed(() => {
        return getIndividual(true)
    })

    const teams = computed(() => {
        const reduced = battle_teams.value.reduce(teamReducer, {'source': individuals, 'teams': []})
        return reduced.teams.sort(getSorter())
    })


    function getIndividual(filter_today) {

        return battle_members.value.map((member) => {
            return getIndiviualScores(member, filter_today)
        }).filter((el) => el.total_count > 0).sort(
            (a,b) => (
                ((b.is_compliant === a.is_compliant) ? 0 : a.is_compliant ? -1 : 1)
                || ((b.is_compliant === true) ? ((b.score_total - a.score_total)) : ((b.compliance_pct - a.compliance_pct)) )
            )
        )

    }

    // ((b.is_compliant === a.is_compliant) ? 0 : a.is_compliant ? -1 : 1)
    // || ((b.is_compliant === true) ? ((b.score_total - a.score_total)) : ((b.compliance_pct - a.compliance_pct)) )

    function getIndiviualScores(member, filter_today) {

        let scores
        if (filter_today) {
            scores = battle_sales.value.filter((el) => el.user_id == member.battle_user.user_id).reduce(
            scoreReducer
            , {'total_count': 0.00, 'fee': 0.00, 'campaign_adjustment': 0.00, 'debug': 0.00, 'accelerated': 0.00, 'sales': [], 'battle_team': member.battle_team })
        } else {
            scores = battle_sales.value.filter((el) => el.user_id == member.battle_user.user_id).reduce(
            scoreReducer
            , {'total_count': 0.00, 'fee': 0.00, 'campaign_adjustment': 0.00, 'debug': 0.00, 'accelerated': 0.00, 'sales': [], 'battle_team': member.battle_team })
        }

        const compliance = battle_compliance.value.find((compliance) => compliance.partner_id == member.battle_user.user_id)
        const compliance_pct = compliance ? compliance.compliance_pct : undefined
        const is_compliant = isCompliant(compliance_pct)
                    // Return the member
        return {
            'first_name': member.battle_user.first_name,
            'last_name': member.battle_user.last_name,
            'battle_team': member.battle_user.battle_team,
            'is_captain': member.is_captain,
            'user_id': member.battle_user.user_id,
            'fee': scores.fee,
            'campaign_adjustment': scores.campaign_adjustment,
            'score_total': scores.fee + scores.campaign_adjustment,
            'total_count': scores.total_count,
            'compliance_pct': compliance_pct,
            'accelerated_score': scores.accelerated, //will be summarized for team only
            'is_compliant': is_compliant,
            'scores': scores
        }
    }

    function teamReducer(teams_acc, cur) {
        /* add members per team */
        let team_members = teams_acc.source.value.filter((el) => el.scores.battle_team == cur.id ).map((member) => {
            return member
        }).sort(
            (a,b) => (
                ((b.is_compliant === a.is_compliant) ? 0 : a.is_compliant ? -1 : 1)
                || ((b.is_compliant === true) ? ((b.score_total - a.score_total)) : ((b.compliance_pct - a.compliance_pct)) )
            )
        )




        if(battle_data.value.team_mode === 'user') {
            team_members = team_members.filter((el) => el.fee > 0)
        }

        const team_score = team_members.reduce((acc, member) => {
            acc.score_fee += member.fee // plain fee
            acc.score_fee_compliant += member.is_compliant ? member.fee: 0 // fee only if compliant
            acc.campaign_adjustment += member.campaign_adjustment // adjustment per campaign
            acc.campaign_adjustment_compliant += member.is_compliant ? member.campaign_adjustment : 0 // adjustment per campaign only if compliant
            acc.score_total += member.score_total // fee plain + campaign adjustment
            acc.score_total_compliant += member.is_compliant ? member.score_total: 0 // fee plain + campaign adjustment only if compliant
            acc.accelerated_score += member.accelerated_score // the accelerated score
            acc.accelerated_score_compliant += member.is_compliant ? member.accelerated_score: 0 // the accelerated score
            return acc
        },{
            'score_fee': 0.00,
            'score_fee_compliant': 0.00,
            'campaign_adjustment': 0.00,
            'campaign_adjustment_compliant': 0.00,
            'score_total': 0.00,
            'score_total_compliant': 0.00,
            'accelerated_score': 0.00,
            'accelerated_score_compliant': 0.00,
            'extra_points': 0.0,
            'sub_total': 0.0,
            'grand_total': 0.0

        })
        // Now it is time to calculate the team values. First the extra points
        const team_extra_points = battle_additional_points.value.reduce((total, score) => {
            if (cur.id === score.battle_team) {
                total += score.points
            }
            return total
        }, 0.0)

        team_score.extra_points = team_extra_points

        team_score.sub_total = team_score.score_total_compliant  + team_score.accelerated_score_compliant
        team_score.grand_total = team_score.score_total_compliant  + team_score.accelerated_score_compliant + team_score.extra_points

        // Wrap it up
        const team = {
            id: cur.id,
            name: cur.name,
            order: cur.order,
            logo_filename: cur.logo_filename,
            team_points: team_score,
            members: team_members
        }
        teams_acc.teams.push(team)

        return teams_acc

    }


    function getMatches() {
        if (options.filter_date == false) {
            return battle_matches.value;
        } else {
            return battle_matches.value.filter((el) => el.match_date == getFilterDate());
        }

    }


    function scoreReducer(acc, sale) {
        if (options.filter_date == false || sale.sale_closed_date.slice(0, 10) == getFilterDate()) {
        sale.accelerators = []  // for debug only
        const accelerated = battle_accelerators.value.filter((el) =>{
            const applicable = isDateApplicable(sale.sale_closed_date, el.start_date_time, el.end_date_time)
            return (el.battle_team == acc.battle_team || el.battle_team == null) && applicable
        }).reduce((acc, cur) => {
            acc += (sale.sum.fee_partner * cur.accelerator) - sale.sum.fee_partner
            // debug
            // sale.accelerators.push({
            //     'team': cur.battle_team,
            //     'accelerator': cur.accelerator,
            //     'start_date_time': cur.start_date_time,
            //     'end_date_time': cur.end_date_time,
            //     'accelerated': (sale.sum.fee_partner * cur.accelerator) - sale.sum.fee_partner
            // })
            // end debug
            return acc
        }, 0.0)

        const campaign_modifier = battle_modifiers.value.find((el1) => el1.campaign_code == sale.campaign_code)
        const campaign_adjustment = campaign_modifier ? campaign_modifier.point_adjustment : 0.0
        const campaign_adjustment_total = campaign_adjustment * parseInt(sale.count) // since sales could be grouped by campaign.

        sale.campaign_adjustment = campaign_adjustment_total
        acc.accelerated += accelerated
        acc.total_count += parseInt(sale.count)
        acc.fee += sale.sum.fee_partner
        acc.campaign_adjustment += campaign_adjustment_total
        acc.debug += campaign_adjustment
            // acc.sales.push(sale) // for debug only
        }
        return acc

    }


    function getSorter() {
        if (options.sort_excludes_additional_points == true) {
            return (a, b) => (
                ( a.order - b.order) || (b.team_points.grand_total - a.team_points.grand_total)
            )
        } else {
            return (a, b) => (
                ( a.order - b.order) || (b.team_points.sub_total - a.team_points.sub_total)
            )
        }
    }

    function chunkedTeams(chunksize) {

        const result = teams.value.sort(getSorter()).reduce((acc, cur, index) => {
            const chunkIndex = Math.floor(index / chunksize)
            if (!acc[chunkIndex]) {
                acc[chunkIndex] = { 'chunck': chunkIndex, 'teams': [] } // start a new chunk
            }
            acc[chunkIndex].teams.push({
                'id': cur.id,
                'name': cur.name,
                'logo_filename': cur.logo_filename,
                'team_points': cur.team_points,
                'members': cur.members,
                'team_index': index +   1
        }
            )
            return acc;
        },[])
        return result
    }

    return  { individuals, individuals_today, teams, battle_data, battle_teams,battle_members,battle_modifiers,battle_sales,battle_compliance,battle_accelerators,battle_additional_points, battle_matches, getMatches, chunkedTeams, getIndividual }

}