export function useBattleTools() {

    function isCompliant(compliance_pct) {
        return compliance_pct == undefined || compliance_pct >= 93
    }

    function isDateApplicable(sale_closed_date, start_date_time, end_date_time) {
        const sale_dt = new Date(sale_closed_date)
        const start_dt = new Date(start_date_time)
        const end_dt = new Date( end_date_time)
        return sale_dt >= start_dt && sale_dt <= end_dt
    }

    function getFilterDate() {

        const url_date = new URL(window.location.href).searchParams.get('date');
        if (url_date) {
            return url_date;
        }
        return new Date().toISOString().slice(0, 10);

    }

    return { isCompliant, isDateApplicable, getFilterDate }

}