import { createRouter, createWebHistory } from "vue-router";

import TeamBattle2024 from '../views/TeamBattle2024.vue'

import Debug from '../views/Debug.vue'

const routes = [
    {
      path: '/',
      name: 'home',
      component: TeamBattle2024
    },
    {
      path: '/debug/',
      name: 'debug',
      component: Debug
    },

];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
