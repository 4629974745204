<template>
    {{ theTeams }}
</template>

<script setup>

import { computed, onMounted } from 'vue'
import { useBattleData } from '../composables/battledata.js'
import { useBattleLoader } from '../composables/battleloader.js'

const { getTeams} = useBattleData({ filter_date: false, sort_excludes_additional_points: true })
const { initBattle } = useBattleLoader()
const theTeams = computed(getTeams)

onMounted(async () => {
    await initBattle()
})



</script>

<style scoped>


</style>