// fetch.js
// import { ref } from 'vue'
import { useKeycloak } from './keycloak.js'
const  { access_token } = useKeycloak()

export function useFetch() {

  const token = new URL(window.location.href).searchParams.get('token');


  function doFetch(url, options = { single : false}){
    const {
      single = false,
    } = options
    const headers = new Headers();

    if (token) {
      console.log('token')
      url = url + '&token=' + token
    } else {
      headers.append('Authorization', 'bearer ' + access_token.value);
    }

    function firstOrEmpty(data) {
         return data.length > 0 ? data[0] : {}
    }

    return fetch(url, { headers: headers}).then((res) => res.json()).then((ret) => single == true ? firstOrEmpty(ret.data) : ret.data)

  }

  return { doFetch }

}